<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12 pt-0 pb-2">
          <h1>
            Financial Job overview for {{ job.jobNumber }}
            | Job Status:
            <span v-if="job.status === 0">Not planned</span>
            <span v-if="job.status === 1">Planned</span>
            <span v-if="job.status === 2">Complete Awaiting Approval</span>
            <span v-if="job.status === 3">Authorised</span>
            <span v-if="job.status === 4">Invoiced</span>
            <span class="float-right">Overview</span>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <p>
            Customer:<br />
            {{ job.customer }}<br />
            <span v-if="job.customerAddressOne">{{ job.customerAddressOne }}<br /></span>
            <span v-if="job.customerAddressTwo">{{ job.customerAddressTwo }}<br /></span>
            <span v-if="job.customerAddressThree">{{ job.customerAddressThree }}<br /></span>
            <span v-if="job.customerAddressFour">{{ job.customerAddressFour }}<br /></span>
            <span v-if="job.customerAddressFive">{{ job.customerAddressFive }}<br /></span>
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <p>
            Site address:<br />
            {{ job.site }}<br />
            {{ job.postCode }}
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <p>
            Work description:<br />
            {{ job.description }}<br />
            PO:
            {{ job.purchase_order }}<br />
            <a
              :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${job.purchase_order_uri}`"
              target="_blank">
              <v-btn x-small>View PO</v-btn>
            </a>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>Minimum charges:</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(charge, index) in job.minimumCharges" :key="index">
                <td>{{ charge.description }}</td>
                <td>{{ charge.charge | priceInPounds | currency }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>BOQs (From quotation):</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Material</th>
                <th>Colour</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(line, index) in job.quotationLines"
                :key="index">
                <td>{{ line.boq }}</td>
                <td>{{ line.material }}</td>
                <td>{{ line.colour }}</td>
                <td>{{ line.unit }}</td>
                <td>{{ line.quantity }}</td>
                <td>
                  @{{ line.price | priceInPounds | currency }} =
                  {{ (line.quantity * line.price) | priceInPounds | currency }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span v-for="(drs, index) in job.drs" :key="index">
            <v-btn
              x-small
              :href="`https://app.yoursafetypal.com/daily_return_sheets/view/${drs.pal_id}`"
              target="blank">
              View DRS #{{ index + 1 }}
            </v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>BOQs (From DRS):</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>BOQ</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(drsBoq, index) in job.drsBoqs" :key="index">
                <td>{{ drsBoq.name }}</td>
                <td>{{ drsBoq.quantity }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>Materials used (From DRS):</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Colour</th>
                <th>Quantity (bags)</th>
                <th>Temperature</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(material, index) in job.materials" :key="index">
                <td>{{ material.type }}</td>
                <td>{{ material.colour }}</td>
                <td>{{ material.quantity }}</td>
                <td>{{ material.temperature }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row v-if="job.plannedJobs">
        <v-col cols="12" sm="12">
          <p>Planned work:</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Vehicle</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(job, index) in job.plannedJobs" :key="index">
                <td>
                  {{ job.date | prettyDate}}
                </td>
                <td>
                  {{ job.vehicle }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>Crew:</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Pay bonus?</th>
                <th>Bonus</th>
                <th>Hours</th>
                <th>Hourly Rate</th>
                <th>NI</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(crew, index) in job.drsWorkers" :key="index">
                <td>{{ crew.name }}</td>
                <td>
                  <span v-if="crew.payBonus === true || crew.payBonus === 1">Yes</span>
                  <span v-else>No</span>
                </td>
                <td>{{ crew.bonus | currency }}</td>
                <td>{{ crew.hours }}</td>
                <td>{{ crew.hourlyRate | priceInPounds | currency }}</td>
                <td>{{ crew.ni | priceInPounds | currency }}</td>
                <td>{{ crew.total | priceInPounds | currency }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>Costs:</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>Mileage</th>
                <th>Vehicle</th>
                <th>MPG</th>
                <th>Fuel</th>
                <th>Running Costs</th>
                <th>Materials</th>
                <th>Bonus</th>
                <th>Overnight</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cost, index) in job.costs" :key="index">
                <td>{{ cost.mileage }}</td>
                <td>{{ cost.vehicle }}</td>
                <td>{{ cost.mpg }}</td>
                <td>{{ ((((cost.fuel * 4.55) /  cost.mpg) * cost.mileage) / 100000) | currency }}</td>
                <td>{{ (cost.mileage * cost.running_cost) | priceInPounds | currency }}</td>
                <td>{{ cost.materials | currency }}</td>
                <td>{{ cost.bonus | currency }}</td>
                <td>{{ cost.overnight | priceInPounds | currency }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row v-if="job.drsImages">
        <v-col>
          <p>Images from the DRS:</p>
          <img
            v-for="(image, index) in job.drsImages"
            :src="image.uri"
            :key="index" />
        </v-col>
      </v-row>
      <v-dialog
        v-model="approveBox"
        max-width="800"
      >
        <v-card>
          <v-card-title>
            Confirm Approval of job: {{ job.jobNumber }}
          </v-card-title>
          <v-card-text class="pt-4">
            Important, please confirm you have reviewed this job and it is correct
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green lighten-2"
              small
              @click="approve"
            >
              {{ $store.state.username }} Approving Job for Invoice
            </v-btn>
            &nbsp;
            <v-btn
              small
              @click="cancelApproving"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showMessage"
        max-width="800"
      >
        <v-card>
          <v-card-title>{{ message }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link to="/live-jobs/not-approved">
              <v-btn
                color="green lighten-2"
                small
              >
                View all Jobs awaiting approval
              </v-btn>
            </router-link>
            &nbsp;
            <v-btn
              small
              @click="showMessage = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import axios from '../../axios';
  
  export default {
    name: 'JobFinancialOverview',
    data() {
      return {
        job: { id: 0 },
        approveBox: false,
        showMessage: '',
        message: '',
      };
    },
    computed: {
      userid() {
        return this.$store.state.userid;
      },
      token() {
        return this.$store.state.token;
      },
    },
    methods: {
      getJob() {
        axios.get(`/liveJobs/getSingleInfo/${this.$route.params.id}.json?token=${this.token}`)
        .then((response) => {
          this.job = response.data;
        });
      },
    },
    mounted() {
      this.getJob();
    },
  };
  </script>
  